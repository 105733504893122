table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;

  tr {
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);

      .labelTableCell {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &:nth-child(even) {
      background-color: rgb(255, 255, 255);
    }
  }

  th,
  td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    text-transform: uppercase;
    font-weight: bold;
  }
}
/* Cabeçalho de Meses */
.sticky-header-row {
background-color: #e0e0e0 !important; /* Cor fixa */
z-index: 2;
border-bottom: 0;
}

/* Linha de Totais */
.sticky-totals-row {
background-color: white !important; /* Já está branco */
z-index: 2;
}

/* Cabeçalho de Almoxarifados */
.sticky-warehouse-header-row {
background-color: #e0e0e0 !important; /* Cor fixa */
z-index: 2;
}

.labelTableCellHeader {
background-color: #e0e0e0
}

.labelTableCellOverall {
background-color: #c3c3c3
}